import { Body, Headline } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IContractNumberModel, IOrganizations } from '../../store/dataModels/interfaces'
import { ContentSection } from '../layout'

interface IProps {
  organisation: IOrganizations
}

const ContractNumbersListComponent: React.FC<IProps> = ({ organisation }) => {
  const { t } = useTranslation()
  return (
    <ContentSection>
      {(organisation.selectedCustomerNumbers?.length > 0 ||
        organisation.selectedBusinessPartnerNumbers?.length > 0) && (
        <ContractNumberComponent
          key={'customer-numbers'}
          title={t(`myOrgsView.customerNumbers`)}
          newContractNumbers={organisation.selectedBusinessPartnerNumbers}
          contractNumbers={organisation.selectedCustomerNumbers}
        />
      )}
      {organisation.selectedLogisticsContractNumbers?.length > 0 && (
        <ContractNumberComponent
          key={'logistics-contract-numbers'}
          title={t(`myOrgsView.logisticsServiceContractNumbers`)}
          contractNumbers={organisation.selectedLogisticsContractNumbers}
        />
      )}
      {organisation.selectedTransportIds?.length > 0 && (
        <ContractNumberComponent
          key={'transport-ids'}
          title={t(`myOrgsView.transportIds`)}
          contractNumbers={organisation.selectedTransportIds}
        />
      )}
      {organisation.selectedPrincipalIds?.length > 0 && (
        <ContractNumberComponent
          key={'principal-ids'}
          title={t(`myOrgsView.principalIds`)}
          contractNumbers={organisation.selectedPrincipalIds}
        />
      )}
    </ContentSection>
  )
}

export const ContractNumbersList = observer(ContractNumbersListComponent)

interface IContractNumberProps {
  newContractNumbers?: IContractNumberModel[]
  contractNumbers: IContractNumberModel[]
  title: string
}

const ContractNumberComponent: React.FC<IContractNumberProps> = ({ newContractNumbers, contractNumbers, title }) => {
  const { t } = useTranslation()
  return (
    <>
      <Headline as="div" size="Nine">
        {title}
      </Headline>

      {(!contractNumbers || contractNumbers.length == 0) && (!newContractNumbers || newContractNumbers.length == 0) && (
        <>
          <StyledRoleRow key="no-numbers">
            <Body as="span" size="Four">
              -
            </Body>
          </StyledRoleRow>
        </>
      )}

      {newContractNumbers &&
        newContractNumbers.map((contractNumber) => (
          <StyledRoleRow key={contractNumber.value}>
            <Body as="span" size="Four">
              {contractNumber.value} {contractNumber.label} ({t(`common.new`)})
            </Body>
          </StyledRoleRow>
        ))}

      {contractNumbers.map((contractNumber) => {
        return (
          <StyledRoleRow key={contractNumber.value}>
            <Body as="span" size="Four">
              {contractNumber.value} {contractNumber.label}
            </Body>
          </StyledRoleRow>
        )
      })}
    </>
  )
}

const StyledRoleRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
`
