import { sendPageInfo, sendUserAction } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'

import { ContentContainer, ContentSection, PostiPage, TopNav } from '../../components'
import { PostiPageLoading } from '../../components/layout/postiPageLoading/PostiPageLoading'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import Localization from '../../locales'
import { RegistrationStep, StoreContext } from '../../store'
import { Before } from './Before'
import { GeneralErrorNotificationWithRetryButton } from './components/GeneralErrorNotificationWithRetryButton'
import { RegistrationForm } from './registrationForm/RegistrationForm'
import { RegistrationMissingPhone } from './RegistrationMissingPhone'
import { RegistrationNotPossibleView } from './RegistrationNotPossible'
import { VerifyEmail } from './verifyEmail/VerifyEmail'
import { VerifyPhone } from './verifyPhone/VerifyPhone'

const shouldSendPageInfo = (step?: RegistrationStep): boolean => {
  return !!step && ![RegistrationStep.GOTO_START_REGISTRATION, RegistrationStep.GOTO_ROOT].includes(step)
}

export const sendConsumerRegistrationPageInfo = (step?: RegistrationStep) => {
  if (shouldSendPageInfo(step)) {
    sendPageInfo({
      language: Localization.CurrentLocale,
      solution: 'oneaccount-consumer-registration',
      domain: window.location.hostname,
      pagePath: `/registration-${step}`,
      pageName: `oneaccount-consumer-registration-${step}`,
    })
  }
}

export const sendConsumerRegistrationErrorUserAction = (message: string) => {
  sendUserAction({
    actionType: 'notification',
    message: message,
    messageType: 'error',
  })
}

const RegistrationMainComponent: React.FC<RouteComponentProps> = observer(() => {
  const { registrationStore } = useContext(StoreContext)
  const requestProgressRef = useRef(false)
  const { t } = useTranslation()
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    sendConsumerRegistrationPageInfo(registrationStore.step)
  }, [registrationStore.step])

  useEffect(() => {
    if (!requestProgressRef?.current) {
      registrationStore.loadInfo().catch(() => {
        setError(true)
      })
      requestProgressRef.current = true
    }
  }, [registrationStore])

  // Send error codes to analytics
  useEffect(() => {
    if (error) {
      sendConsumerRegistrationErrorUserAction('registrationMainView_init_failed')
    }
  }, [error])

  useEffect(() => {
    if (registrationStore.step === RegistrationStep.GOTO_START_REGISTRATION) {
      location.href = '/start-registration'
    }
  }, [registrationStore.step])

  const handleProceedToCreation = useCallback(() => {
    registrationStore.setStep(RegistrationStep.GOTO_START_REGISTRATION)
  }, [registrationStore])

  const hideLogOutButton = ![
    RegistrationStep.REGISTRATION_FORM,
    RegistrationStep.REGISTRATION_FORM_FOR_OLD_USERS,
    RegistrationStep.VERIFY_PHONE,
    RegistrationStep.VERIFY_EMAIL,
    RegistrationStep.ALREADY_REGISTERED,
    RegistrationStep.PHONE_NUMBER_MISSING,
  ].some((step) => step === registrationStore.step)

  const renderView = () => {
    if (registrationStore.loadingInfo) {
      return <PostiPageLoading id="registrationLoading" />
    }
    switch (registrationStore.step) {
      case RegistrationStep.GOTO_START_REGISTRATION:
        return <PostiPageLoading id="registrationLoading" />
      case RegistrationStep.WELCOME:
        return <Before proceed={handleProceedToCreation} />
      case RegistrationStep.REGISTRATION_FORM:
        return <RegistrationForm isOldUser={false} />
      case RegistrationStep.REGISTRATION_FORM_FOR_OLD_USERS:
        return <RegistrationForm isOldUser={true} />
      case RegistrationStep.VERIFY_PHONE:
        return <VerifyPhone />
      case RegistrationStep.VERIFY_EMAIL:
        return <VerifyEmail />
      case RegistrationStep.ALREADY_REGISTERED:
        return <RegistrationNotPossibleView email={registrationStore.info?.email} />
      case RegistrationStep.PRE_CHECK_FAIL:
        return <RegistrationNotPossibleView />
      case RegistrationStep.REGISTRATION_UNAVAILABLE:
        return <RegistrationNotPossibleView />
      case RegistrationStep.PHONE_NUMBER_MISSING:
        return <RegistrationMissingPhone />
    }
  }

  return (
    <>
      <TopNav
        hideNavigation={true}
        promptBeforeLogout={!error}
        hideLogout={!error && hideLogOutButton}
        overrideLogOutButtonText={t('registration.buttons.exitRegistration')}
        exitRegistrationModalBodyText={
          registrationStore.step === RegistrationStep.ALREADY_REGISTERED ? '' : t('registration.exitModal.text')
        }
      />
      <PostiPage>
        {error && (
          <ContentContainer>
            <ResponsiveHeadline as="h1">{t('registration.before.title')}</ResponsiveHeadline>
            <ContentSection>
              <GeneralErrorNotificationWithRetryButton />
            </ContentSection>
          </ContentContainer>
        )}
        {renderView()}
      </PostiPage>
    </>
  )
})

export const RegistrationMainView = withRouter(RegistrationMainComponent)
