import {
  CloseIcon,
  EnvelopeIcon,
  LockIcon,
  MultipleUsersIcon,
  Notification,
  PhoneIcon,
} from '@postidigital/posti-components/build/brand'
import { XyzTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory, withRouter } from 'react-router'
import styled, { css } from 'styled-components'

import { ContentSection, ModalDialog } from '../../components'
import { FeatureFlags } from '../../featureFlags'
import Localization from '../../locales'
import { StoreContext } from '../../store'
import { IMainViewState } from '../../store/dataModels/interfaces'
import { breakpoint2, formatDate } from '../../utils'
import { EditButton } from '..'
import { StaticInputLayout } from '../StaticInputLayout'
import { ResponsiveBody } from '../typography/ResponsiveBody'
import { ResponsiveHeadline } from '../typography/ResponsiveHeadline'
import { ResponsiveLink } from '../typography/ResponsiveLink'
import { EditEmailVerification } from './EditEmail/EditEmailVerification'
import { NameFields } from './NameFields'

const MainInfoComponent: React.FC<RouteComponentProps> = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const { userStore, mainViewStore, editPreferredNameStore, terminateAccountViewStore, infoStore } = useContext(
    StoreContext
  )

  const editSelected = useCallback(
    (selectedField: IMainViewState) => {
      if (selectedField === IMainViewState.EditPreferredName) {
        editPreferredNameStore.initialize(userStore.identities?.consumerIdentity?.preferredName)
      }

      mainViewStore.setState(selectedField)
    },
    [editPreferredNameStore, mainViewStore, userStore.identities?.consumerIdentity?.preferredName]
  )

  const handleDeleteAccountClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      const to = event.currentTarget.getAttribute('href')

      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()

      if (!to) {
        return null
      }

      terminateAccountViewStore.initialize(infoStore, userStore)
      history.push(to)
    },
    [history, infoStore, terminateAccountViewStore, userStore]
  )

  const handleRedirectEditPhone = useCallback(() => {
    const destination =
      infoStore.userInfo?.phoneVerification?.isExpired === false ? IMainViewState.VerifyPhone : IMainViewState.EditPhone
    editSelected(destination)
  }, [editSelected, infoStore.userInfo?.phoneVerification?.isExpired])

  // TODO is this modal in use? There is a bug here and modal will never close
  const { setState } = mainViewStore
  const closeModal = () => {
    setState(IMainViewState.Info)
  }

  const supportLinkHref = window['postienv']?.customerSupportLinks
    ? window['postienv']?.customerSupportLinks[Localization.CurrentLocale]
    : '#'

  return (
    <>
      <StyledWrapper>
        <StyledContentWrapper>
          <ContentSection>
            {infoStore.isConsumer && userStore.identities?.consumerIdentity?.isTerminated === true && (
              <Notification dismissible={false} contentType="informational">
                <Trans
                  i18nKey="mainView.terminationInfo.text"
                  values={{ date: formatDate(userStore.identities.consumerIdentity.terminationDate) }}
                  // TODO: Change link to point to account termination cancel page once it's done in OAN-2341
                  components={[
                    <ResponsiveLink
                      size="xs"
                      id="termination_customer_support_link"
                      href={`${supportLinkHref}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />,
                  ]}
                />
              </Notification>
            )}
            {infoStore.isPartialConsumer && (
              <Notification dismissible={false} contentType="warning">
                {t('detailsView.partialContent')}
              </Notification>
            )}

            <ResponsiveHeadline size="Eight" as="h2">
              {t('mainView.loginDetails')}
            </ResponsiveHeadline>
            <ResponsiveBody>{t(`mainView.emailInfo`)}</ResponsiveBody>
            <EditEmailVerification />
            <StyledButtonWrapper>
              <StaticInputLayout label={t(`general.email`)} content={infoStore.userInfo.email} Icon={EnvelopeIcon} />
              <EditButton
                id="edit_email_button"
                onClick={() => editSelected(IMainViewState.EditEmail)}
                disabled={infoStore.isPartialConsumer}
              />
            </StyledButtonWrapper>
            <StyledButtonWrapper>
              <StaticInputLayout label={t(`general.password`)} content="********" Icon={LockIcon} />
              <EditButton id="edit_password" onClick={() => editSelected(IMainViewState.ChangePassword)} />
            </StyledButtonWrapper>
          </ContentSection>
          <ContentSection>
            <ResponsiveHeadline size="Eight" as="h2">
              {t('mainView.name')}
            </ResponsiveHeadline>
            <NameFields />
          </ContentSection>
          {FeatureFlags.EnablePreferredNameEditing && infoStore.isConsumer && (
            <StyledButtonWrapper>
              <StaticInputLayout
                label={t(`mainView.preferredName`)}
                content={userStore.identities.consumerIdentity?.preferredName}
                Icon={MultipleUsersIcon}
              />
              <EditButton
                id="edit_nickname_button"
                onClick={() => editSelected(IMainViewState.EditPreferredName)}
                disabled={infoStore.isPartialConsumer}
              />
            </StyledButtonWrapper>
          )}
          {infoStore.isConsumer && userStore.identities.consumerIdentity.consumerPhoneNumber && (
            <>
              <ResponsiveBody>{t(`detailsView.phoneInfo`)}</ResponsiveBody>
              <StyledButtonWrapper>
                <StaticInputLayout
                  label={t(`general.mobilePhoneNumber`)}
                  content={userStore.identities.consumerIdentity.consumerPhoneNumber.value}
                  Icon={PhoneIcon}
                />
                <EditButton
                  id="edit_phone_button"
                  onClick={handleRedirectEditPhone}
                  disabled={infoStore.isPartialConsumer}
                />
              </StyledButtonWrapper>
            </>
          )}
        </StyledContentWrapper>

        {terminateAccountViewStore.isAccountTerminationEnabledForUser(infoStore, userStore) && (
          <ContentSection>
            <ResponsiveBody>{t(`mainView.accountTermination.intro`)}</ResponsiveBody>
            {infoStore.isPartialConsumer ? (
              <ResponsiveLink
                href="/terminateAccount"
                onClick={handleDeleteAccountClick}
                disabled={infoStore.isPartialConsumer}
                color={XyzTheme.color.neutralPassiveGray}
              >
                {t(`mainView.accountTermination.accountTerminationLink`)}
              </ResponsiveLink>
            ) : (
              <ResponsiveLink href="/terminateAccount" onClick={handleDeleteAccountClick}>
                {t(`mainView.accountTermination.accountTerminationLink`)}
              </ResponsiveLink>
            )}
          </ContentSection>
        )}

        {(mainViewStore.state === IMainViewState.EmailVerificationSuccess ||
          mainViewStore.state === IMainViewState.EmailVerificationFailure) && (
          <ModalDialog visible={true}>
            <StyledCloseIcon>
              <CloseIcon width="24" height="24" color={XyzTheme.color.brandNavy} onClick={closeModal} />
            </StyledCloseIcon>
          </ModalDialog>
        )}
      </StyledWrapper>
    </>
  )
}

export const MainInfo = withRouter(observer(MainInfoComponent))

export const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`

const StyledContentWrapper = styled('div')`
  width: 100%;
  justify-content: center;
`

export const StyledButtonWrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;

    @media ${breakpoint2.tabletAndUp} {
      max-width: 30rem;
    }
  `
)

const StyledCloseIcon = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
`
