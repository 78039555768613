/* eslint-disable */
export default {
  common: {
    login: "Login",
    languageSelection: "Language selection",
    new: "New",
  },
  serviceLinkLabel: {
    services: 'Services',
    ompaposti: 'OmaPosti',
    moving: 'Change of address',
    shop: 'Online shop',
  },
  accountRecovery: {
    time: {
      minute: "minute",
      second: "second",
      minutes: "minutes",
      seconds: "seconds",
    },
    initial: {
      title: "Recover your personal account",
      content: "If you have forgotten your username/email address you use to sign in, you could recover your account and reset your password by authenticating with your online bank credentials, Mobile ID, or ID card. If you have not registered a Posti account with your personal identity code, you will be redirected to create a new account for Posti's consumer services.",
      buttonText: "Continue to identification",
    },
    reset: {
      title: "Recover your account",
      subTitle: "Hello {{name}}. Welcome back!",
      content0: "To recover your account, please review and update your Posti account details from below. You can change your email address and password.",
      content1: "Your email address is used to login to Posti services.",
      sessionModal: {
        title: "Your session will expire soon.",
        content: "You will be logged out for your account protection. You can continue to edit your information or exit account recovery view.<1 />The session will expire in <0>{{time}}</0>.",
        exit: "Exit",
        continue: "Continue",
      },
    },
    corporate: {
      emailStep: {
        title: "Forgot your password",
        content0: "If you have forgotten your password, you can reset it using a verification code sent to the email address that you use to sign in. If you are unable to receive verification code via email, please contact the main user of your business account for assistance.",
        content1: "If you are consumer user, please ",
        contentLink: "recover your account via Strong Customer Authentication.",
        inputLabel: "Email",
        ctaLabel: "Send verification code",
        mailReset: {
          title: "Email verification and password change",
          content0: "Email of your account needs to be verified and password needs to be reset.",
        }
      },
      passwordStep: {
        title: "Set new password",
        content0: "The 6-digit verification code has been sent to <0>{{email}}</0>.",
        content1: "If you do not receive an email that contains the verification code or provided email address is wrong, please go ",
        content2: "Make sure to check your spam or junk folder if you do not see the email in your inbox.  If it still doesn't work, please contact the main user of your business account for assistance.",
        contentLink: "back and try again.",
        codeInputLabel: "Verification code",
        ctaLabel: "Save",
      },
      successStep: {
        title: "Password reset successful",
        content: "You have successfully reset your password and can proceed to login with the new password. Please remember to keep your password safe and secure.",
        ctaLabelLogin: "Continue to login",
        ctaLabelBusinessChannels: "Go to service channels",
      },
      error: {
        codeValidation: "Invalid code",
      },
      message: {
        success: "The 6-digit verification code has been sent to the provided email address.",
        badRequest: "You have requested too many codes, please try one hour later.",
        internalError: "Something went wrong, try to recover your account later.",
        codeExpired: "The verification code has expired, please request a new one.",
        codeNotFound: "The entered verification code is not found for the provided email address. Please check if you have entered the code correctly.",
        emailMismatch: "The entered verification code is not found for the provided email address. Please check if you have entered the code correctly.",
        codeUsed: "The verification code is already used.",
        rateLimit: "You have exceeded your hourly reset limit, try again later.",
      },
    },
  },
  invite: {
    loading: "Loading invitation. Please wait a moment.",
    acceptTitle: "Accept Invitation from {{orgName}}",
    registerTitle: "Create a business account",
    invalidTitle: "Oops, invitation cannot be accepted",
    invalidContent: "It might be because of the following reasons:",
    invalidReason1: "The invitation has expired.",
    invalidReason2: "The invitation link is broken or incomplete.",
    invalidReason3: "The invitation has been already accepted.",
    errorTitle: "Oops, something went wrong",
    errorContent: "Please try again later.",
    accept: {
      salute: "Dear customer ({{email}}),",
      preface: "Welcome onboard. You have been granted access rights to Posti's business services. ",
      joinMessage: "{{orgName}} ({{businessId}}) invites you to join",
      invitedBy: "Invited by main user {{mainUserName}}",
      invitedByPosti: "Invited by Posti",
      supportMessage: "If you need support, please contact your organization’s main user.",
      acceptAndCreateButton: "Accept and create account",
      acceptButton: "Accept",
      declineButton: "Decline",
    },
    register: {
      preface: "You have been invited to use Posti’s business services. First, create a user account.",
      orgInfo: "The account will be connected to {{ orgName }}.",
      usernameInfo: "Your email address will act as your username for the services.",
      formTitle: "Complete your personal information",
      enterPassword: "Enter password",
      passwordHelptext: "The password must have at least 8 characters. It must contain both upper and lower case letters, numbers, and at least one special character. The password can’t be the same as the username (email address).",
      firstName: "First name",
      lastName: "Last name",
      enter: "Enter",
      lastNameHelptext: "Enter last name",
      enterPhoneNumber: "Enter phone number (optional)",
      phoneNumberHelp: "We recommend that you also provide your phone number even though the field is not mandatory.",
      phoneNotValid: "Phone number is not valid",
      firstNameNotValid: "First name has unallowed characters",
      firstNameEmpty: "Enter a valid first name",
      lastNameNotValid: "Last name has unallowed characters",
      lastNameEmpty: "Enter a valid last name",
      backButton: "Back",
      createAccountButton: "Create account",
      hidePassword: "Hide password",
      showPassword: "Show password",
      errorTitle: "There {{ is }} {{ numeral }} {{ problem }} in this form.",
      problemSingular: "problem",
      problemPlural: "problems",
      isSingular: "is",
      isPlural: "are",
      one: "a",
      two: "two",
      three: "three",
      four: "four",
    },
    done: {
      acceptedTitle: "Accepted",
      acceptedText: "You're all set! You can now use Posti's business services.",
      registeredTitle: "Account created",
      registeredText: "Welcome to Posti’s business services, {{ name }}!",
      omapostiproButton: "Log in to OmaPosti Pro",
      smartshipButton: "Log in to Smartship",
      myaccountButton: "Log in to My Account",
      registeredButton: "Log in to OmaPosti Pro",
    },
    footer: {
      title: "Do you need help?",
      links: "<a href=\"https://www.posti.fi/en/customer-support/contact-us#business\" target=\"_blank\">Customer service</a>",
      path: "https://www.posti.fi/en/customer-support/contact-us#business",
      label: "Customer service",
    },
  },
  sideMenu: {
    myAccount: "My account",
    myDetails: "My personal details",
    myAddress: "My address",
    mySettings: "Settings",
    myOrgs: "My organizations",
    userIdLink: "My personal details",
    feedback: {
      title: "Feedback and data",
      description: "You can give feedback on Posti's services, make an address change protection or make a data request of your personal data stored in Posti's registers.",
      link: "Data request",
      mobileLink: "Data request",
    },
  },
  detailsView: {
    phoneNumber: "Phone number",
    comLang: "Language of communication",
    language: "Language",
    streetAddress: "Street Address",
    postalCode: "Postal code",
    city: "City",
    addressInfo: "You can easily submit a notification of change of address to both Posti and the Digital and Population Data Services Agency and check your addresses on the Change of address site. Your address history will be shown on the register extract up to 20 years.",
    linkInfo: "Submit a notification of change of address",
    markConsent: "Marketing consent",
    dataSharing: "Data sharing with Posti's partners",
    contentText: "By consenting to receiving marketing communications by email or phone, you will be among the first to hear about Posti’s new services and service features, current customer benefits, offers and competitions.",
    contentIWantBenefits: "I want to receive benefits from Posti",
    byEmail: "By email",
    infoPart0: "I want to receive benefits from Posti’s selected partners. ",
    infoPart1: "Posti or its partner may send you targeted advertising or contact you by telephone.",
    infoPart2: "We always ensure that data is processed responsibly. Email addresses are not disclosed outside of Posti.",
    byMail: "By mail",
    byPhone: "By phone",
    infoPart3: "If you consent to your customer information being connected to online data ",
    infoPart4: "(such as browsing data from Posti’s services), Posti’s partners will be able to offer you content that interests you and you will see more targeted marketing through various channels. Your choice will not affect the amount of advertising you see.",
    browserData: "I consent to my browsing data being connected to my customer information",
    infoPart5: "You can manage cookie-based targeting and Posti’s own advertisement targeting on ",
    infoHere: "the Cookies page. ",
    infoPart55: " Read more about our ",
    infoPart6: "privacy policy.",
    infoPart7: "Updating contact details to Posti’s partners",
    infoPart8: "My contact information may be updated by Posti to their customer organizations where I am a customer or a member.",
    langError: "There was a problem with updating the language",
    marketingError: "There was a problem with updating your marketing permissions",
    marketingSuccess: "Marketing permissions updated successfully",
    phoneInfo: "This is mandatory for your personal account.",
    serviceContractsTitle: "Electronic con­sumer ser­vices",
    contractAccepted: "Terms and conditions accepted",
    rekisteriseloste: "File description for electronic consumer services",
    accountTerminationDescription: "If you wish, you can stop using Posti’s electronic services and delete your user account. If the account is deleted, your right to use all of the consumer services will terminate after 14 days.",
    accountTerminationLink: "Stop using Posti’s electronic services",
    addressHistoryContent: "You can check your own address information for 10 years. You only see your own data in the report, not for example family members. Log in to the Change of Address service to see your address history.",
    addressMissingContent: "Address information is unavailable in this service at the time.",
    partialContent: "Parts of your data is unavailable to view and edit in this service at the time.",
  },
  general: {
    cancel: "Cancel",
    continue: "Continue",
    saveChanges: "Save changes",
    email: "Email address",
    edit: "Edit",
    close: "Close",
    password: "Password",
    lastName: "Last name",
    preferredName: "Preferred name",
    mobilePhoneNumber: "Phone number",
    error: "Something went wrong. Please try again.",
    remove: "Remove",
    logout: "Log out",
    loading: "Loading",
  },
  mainView: {
    title: "Account details",
    emailInfo: "Your email address is also your username.",
    firstNames: "First names",
    loginDetails: "Login details",
    name: "Name",
    preferredName: "Preferred name",
    finishVerifyEmail: "You have unfinished email change, please verify your email address.",
    editPhoneNumber: {
      title: "Change your phone number",
      currentPhoneNumber: "Current phone number",
      newPhoneNumber: "New phone number",
      verifyInfoTitle: "Verify your new phone number!",
      verifyInfoText: "A six digit verification code will be sent to the new phone number.",
      verifyTitle: "Verify your new phone number",
      verifyText: "A 6 digit verification code has been sent to {{ newPhoneNumber }}. ",
      verifySuccess: "Your phone number has been changed.",
      verifying: "Verifying code",
      invalidCode: "The code was incorrect or expired",
      invalidPhoneNumber: "Please enter a Finnish phone number",
      sendNewCode: "Send new code",
      codeWasResent: "The code was resent",
      tooSoonAfterFailed: "Please wait a moment before trying again",
      failedCode: "The code has been expired. Please send a new code below",
    },
    editEmail: {
      title: "Change email",
      currentEmail: "Current email",
      newEmail: "New email",
      verifyTitle: "Verify your new email!",
      validationError: "Check spelling",
      verifyText: "An email will be sent to the new email address. Click the verification link to complete the email change. The link in the email is valid for 30 minutes. ",
      error: "There was a problem with changing your email.",
      exitButton: "Back to My account",
      tryAgainButton: "Change email",
      pendingVerification: "Pending verification",
    },
    changePassword: {
      title: "Change password",
      oldPassword: "Old password",
      newPassword: "New password (minimum 8 characters)",
      newPasswordAgain: "Verify new password",
      noMatch: "The passwords do not match",
      error: {
        ERROR: "Changing the password failed",
        NEW_PASSWORD_NOT_VALID: "The password must have at least 8 characters. It must contain both upper and lower case letters, numbers, and at least one special character. The password can’t be the same as the username (email address).",
        NEW_PASSWORD_IS_SAME_AS_OLD_PASSWORD: "The new password cannot be the same as the old password",
        OLD_PASSWORD_IS_INCORRECT: "The old password was wrong",
        NEW_PASSWORD_FIELDS_DO_NOT_MATCH: "The fields for new password did not match",
      },
      success: {
        OK: "Password was changed successfully",
      },
      logout: "Your password has been changed. You will be logged out in a few seconds. Please log in with the new password.",
    },
    changeName: {
      firstName: "First name",
      lastName: "Last name",
      invalidEmptyFirstName: "First name cannot be empty",
      invalidEmptyLastName: "Last name cannot be empty",
      invalidCharacterFirstName: "First name has unallowed characters",
      invalidCharacterLastName: "Last name has unallowed characters",
      error: {
        ERROR: "Name change failed",
        NAME_HAS_INVALID_CHARACTERS: "Name change failed. Name contains unallowed characters",
      },
    },
    editPreferredName: {
      title: "Change preferred name",
      oldPreferredName: "Current preferred name",
      newPreferredName: "New preferred name",
      error: "There was a problem with changing your preferred name",
      success: "Preferred name changes successfully",
    },
    terminationInfo: {
      text: "Your personal account will be completely closed on {{date}}. You can still login to Omaposti until this date. If you change your mind before this date, please <0>contact Posti Customer Service.</0>",
    },
    accountTermination: {
      intro: "If you wish, you can stop using Posti's online services and delete your account.",
      accountTerminationLink: "Delete your Posti account",
    },
  },
  termination: {
    selection: {
      title_business: "Select the account to be deleted",
      title_consumer: "Select the account to be deleted",
      title_both: "These are the accounts you can delete",
      backlink: "My account",
      intro_business: "<p><strong>Select account type to proceed:</strong></p>",
      intro_consumer: "<p><strong>Select account type to proceed:</strong></p>",
      intro_both: "<p>You have both personal and organization accounts, please select one to proceed. If you want to terminate both, you can come back to this flow to delete another one.</p><p><strong>Select one to proceed:</strong></p>",
      error_accountTypeRequired: "Select at least one account to proceed",
      back: "Back",
      next: "Next",
      consumer: {
        title: "Personal account",
        description: "This is the account you use for accessing Omaposti and other personal Posti's services.",
        b2b_needs_to_be_terminated_first: "Your business account needs to be terminated before you can close your personal account.",
      },
      business: {
        title: "Organization account",
        description: "",
        and: "and",
        admin_warning: "Hey, you are the last main user of {{organizationNames}}. To make sure someone will take care of this account, ",
        admin_warning_link: "assign a new main user before leave.",
      },
    },
    feedback: {
      title: "Before you go...",
      subtitle: "Could you tell us why would you like to leave?",
      backlink: "My account",
      dontKnowHowToUseProduct: "I don't know how to use your product",
      foundAnotherProduct: "I found another product I like better",
      didNotUseEnough: "I didn't use it enough",
      missingFeatures: "There are features missing",
      leavingFinland: "I am leaving Finland",
      privacyConsiderations: "Privacy consideration and marketing communication",
      other: "Other reasons",
      textInput: "Tell us more...",
      infoIconAlt: "Information",
      privacyInfo: "You can <a href=\"{{url}}\">disable the marketing communications here</a>.",
      next_button: "Proceed to delete",
      back_button: "Back",
    },
    confirmation: {
      backlink: "My account",
      thankYouForFeedback: "Thanks for your feedback. ",
      input_required: "Required field",
      back: "Back",
      next: "Delete account",
      dialog: {
        x_tooltip: "Close",
        cancel: "Cancel",
        confirm: "Confirm",
      },
      OrphanedAccountTermination: {
        title: "Confirm account termination",
        subtitle: "",
        warning: "If you choose to continue deleting your account, it will be deleted immediately and you can no longer log in with your Posti account.",
        content: "\n        <p>Hi{{name}},</p>\n        <p>{{feedbackThanks}}Before you go, know this...</p>\n        <p><strong>{{warning}}</strong></p>\n        <p>However since your account is not linked to your personal identity and it has no access to any organizations, it will be automatically removed in a few weeks anyway. So deleting your account now should be a pretty safe thing to do...</p>\n        ",
        understand: "I am sure that I want to delete my account.",
        dialogTitle: "Are you sure?",
        dialogContent: "Your account will be terminated immediately and you cannot login to Posti's online services anymore.",
      },
      BusinessAccountTermination: {
        title: "Delete your organization account",
        subtitle: "",
        warning_businessOnly: "Your account and your access to the organizations will be removed right away and you will be logged out of Posti's online services",
        warning_consumer: "Your access to the organizations will be removed right away, but you can still log in and use Posti's consumer services",
        content: "\n        <p>Hi{{name}},</p>\n        <p>{{feedbackThanks}}We're sorry to see you go. Before you go, know this...</p>\n        <p><strong>{{warning}}</strong></p>\n        <span>After termination, you will not be able to use all these services:</span>\n        <ul>\n          <li>Send and track your customers' shipment efficiently</li>\n          <li>Report and analysis tool as well as invoice summery to gather most important information in one view.</li>\n          <li>Multi-channel to communicate with your customers</li>\n        </ul>\n        <span>You will be removed right away from:</span>\n        <ul>\n          {{organizationList}}\n        </ul>\n        ",
        understand: "I am sure that I want to leave all the organizations.",
        dialogTitle: "Confirm organization account deletion?",
        dialogContent: "Your access to organizations will be revoked immediately.",
      },
      ConsumerAccountTermination: {
        title: "Delete your personal account",
        subtitle: "",
        warning_consumerOnly: "After the account is deleted, your right to use all of the consumer services will terminate after 14 days. Your data will be totally removed in about a month.",
        warning_business: "Your personal acount will be terminated after 14 days and all of your personal data will be delete in about a month. You can still continue using Posti's business services with this same username and password.",
        content: "\n        <p>Hi{{name}},</p>\n        <p>{{feedbackThanks}}We're sorry to see you go. Before you go, know this...</p>\n        <p><strong>{{warning}}</strong></p>\n        <span>After total termination, you will not be able to use all these services:</span>\n        <ul>\n          <li>OmaPosti, easy parcel tracking and receive letter, invoice in real time.</li>\n          <li>Your pickup point, your preferred fixed pickup location.</li>\n          <li>Mail redirection, easy modification of your address and forward your mail to your desired location.</li>\n          <li>Address book, keep the address information of your close circle up to date.</li>\n        </ul>\n        ",
        understand: "I am sure that I want to close the personal account and delete all related details.",
        dialogTitle: "Confirm personal account deletion",
        dialogContent: "Once confirmed, your right to use all of the consumer services will terminate after 14 days.",
      },
    },
    processing: {
      title: "Please wait...",
      subtitle: "This should only take a moment",
    },
    done: {
      OrphanedAccountTermination: {
        title: "Account removed",
        message: "<p>Sad to see you leave, we hope to see you again soon!</p>",
        button: "WWW.POSTI.FI",
      },
      BusinessAccountTermination: {
        title: "Organization account removed",
        message: "<p>We have removed you from all organizations, but you can still continue using Posti's online services as a consumer user.</p>",
        button: "Back to My account",
      },
      ConsumerAccountTermination: {
        title: "Your account will be removed in two weeks",
        message: "<p>Your personal account will be removed in two weeks. Until then you can continue usin Posti's online services. If you have any letters stored in OmaPosti, remember to save them on your own computer before your account is terminated.</p>",
        button: "Back to My account",
      },
    },
    footer: {
      title: "Do you need help?",
      links: "<a href=\"https://www.posti.fi/en/customer-support\" target=\"_blank\">Customer service</a>",
      path: "https://www.posti.fi/en/customer-support",
      label: "Customer service",
    },
  },
  myOrgsView: {
    title: "My organizations",
    organizationPhoneNumber: "Contact phone number",
    info: "You have permissions to the following organizations and services.",
    askMoreInfo: "If you have questions related to any services, please contact your organization's main user.",
    editPhoneNumber: {
      error: "Changing phone number failed, please try again later.",
      success: "Contact phone number changed successfully",
      invalidPhoneNumber: "Please enter a valid phone number",
    },
    customerNumbersContracts: "Contracts",
    customerNumbers: "Customer numbers",
    logisticsServiceContractNumbers: "Logistics contract numbers",
    transportIds: "Transport IDs",
    principalIds: "Principal IDs",
    mainUsers: "Main users",
    leaveOrg: "Leave organization",
    editOrg: "Edit organization",
    modalTitle: "Removing an organization",
    modalText: "You will lose all rights to use Posti services on behalf of this organization.",
  },
  errors: {
    general: {
      title: "Something went wrong",
    },
    badUrl: {
      title: "This link is no longer valid",
    },
    accountTermination: {
      generic: {
        title: "Something went wrong",
        message: "Your account termination failed. You might want to try again...",
      },
    },
    refresh: "Back to Start",
    generalErrorWithRetry: {
      somethingWentWrong: "Something went wrong. Please",
      tryAgain: "try again",
    },
  },
  csat: {
    title: "How was this experience?",
    thanks: {
      title: "Thank you for your feedback!",
      text: "Your input helps us improve our service.",
    },
    terrible: {
      label: "Terrible",
      aria: "Terrible experience",
    },
    bad: {
      label: "Bad",
      aria: "Bad experience",
    },
    ok: {
      label: "Ok",
      aria: "Ok experience",
    },
    good: {
      label: "Good",
      aria: "Good experience",
    },
    great: {
      label: "Great",
      aria: "Great experience",
    },
  },
  registration: {
    exitModal: {
      title: "Exit registration and log out",
      text: "If you exit now you can continue the registration later.",
    },
    before: {
      title: "Create a Posti online services account",
      hello: "Hello!",
      welcomeToPosti: "Great to have you as a registered customer of Posti online services.\n\nBy registering, you can use our free of charge OmaPosti, as well as other services. OmaPosti is at its best as an app on the phone. You can also use it online without installation.\n\nWe recommend OmaPosti because:",
      bullets: {
        omaPosti: "OmaPosti makes it easy to track parcels. Stay fully updated on your parcel's journey by logging into OmaPosti.",
        pickupPoint: "You can reduce the amount of paper at home by directing letters and invoices to OmaPosti’s electronic mailbox.",
      },
    },
    accountAlreadyExists: {
      viewTitle: "This account is ready to use",
      youAlreadyHaveAccount: "You have a Posti account with the email address",
      loginOrForgotPasswordInstruction: "Please log in using your email address and password by clicking the button below. You can reset your password if needed.",
      buttonLoginWithEmail: "Continue to login",
      buttonForgotMyPassword: "Forgot my password",
    },
    underMinAge: {
      title: "Registration is not possible",
      notificationText: "Unfortunately, you can't register as a user of Posti online services.",
      goToPostiFiButtonText: "Continue to posti.fi",
      descriptionText: "You may use the services if:",
      descriptionList: "<0>You have a Finnish personal identity code.</0>\n<0>You are at least 15 years old.</0>\n<0>Your information is in Posti’s address register.</0>\n<0>This is your only Posti account. Contact Posti’s customer service if you already have an account with another personal identity code.</0>",
    },
    unavailable: {
      title: "Service is temporarily unavailable",
      notificationText: "This feature is currently unavailable. We’re working on resolving the issue and apologize for the inconvenience.",
      goToPostiFiButtonText: "Continue to posti.fi",
 },
    incomplete: {
      title: "Fill in the required information",
      text: "Welcome back! You can use Posti online services after completing your account information. Please click below to log in with your online bank credentials. After this you can fill in the missing information.",
    },
    create: {
      title: "Create an account",
      titleOldUsers: "Finalize your account",
      topic: "Enter your information",
      topicExplanation: "By registering, you create a personal Posti account, which gives you access to various digital services of Posti Group companies (“Posti”), such as OmaPosti and the services it contains. The services are described in the Posti web site, applicable terms and/or in connection with the respective digital services.",
      enterPhone: "Enter phone number",
      enterEmail: "Enter email address",
      enterPassword: "Enter password",
      phoneInstructions: "It is important to enter your own personal phone number. This number is used, for example, for parcel receiving notifications. The phone number must be Finnish.",
      emailInstructions: "Your email address serves as your account’s username. You can use it to authenticate yourself in Posti’s online services.",
      passwordInstructions: "The password must have at least 8 characters. It must contain both upper and lower case letters, numbers, and at least one special character. The password can’t be the same as the username (email address).",
      preferredNameInstructions: "You may add a preferred name.",
      termsOfUseHeadline: "Terms of use, consents and customer communications",
      optional: "Optional",
      acceptTerms: {
        iAcceptThe: "I accept the ",
        termsOfUseLinkText: "terms of use",
        andHaveReadThe: " and have read the ",
        privacyStatementLinkText: "privacy statement",
      },
      acceptRegistryUpdate: {
        label: "I accept that my contact information may be updated by Posti to their customer organizations where I am a customer or a member.",
        additionalInfo: "Please note that your contact information can still be updated by Posti Jakelu Oy from the address register to such organizations who already have your contact information, if you do not have an address disclosure ban. You can find more information and make the disclosure ban in accordance with the instructions ",
        additionalInfoLinkText: "here.",
      },
      acceptMarketing: "I want to receive news and offers from Posti and their partners via electronic channels. Consent can be withdrawn at any time.",
      links: {
        termsOfUse: "https://www.posti.fi/en/customer-support/terms-and-data-protection/cash-and-consumer/terms-of-use-for-electronic-consumer-services",
        privacyStatement: "https://www.posti.fi/en/customer-support/terms-and-data-protection/data-protection/posti-customer-register-for-electronic-consumer-services",
        disclosureBan: "https://www.posti.fi/en/private/letters-and-mail/delivery-and-change-of-address/disclosure-ban",
      },
      youMayTerminate: "You may terminate your Posti account at any time.",
      validation: {
        enterPhone: "Enter a valid phone number",
        enterEmail: "Enter a valid email address",
        enterPassword: "Enter a valid password",
        preferredName: "Preferred name must not contain spaces and can’t be longer than 100 characters",
        acceptTerms: "Accept the terms of use to continue creating an account",
      },
      oldUserNotification: "It looks like you already have a Posti account, but some of the information is missing. You can use your account after you review and complete your account information.",
      errorMessages: {
        emailTaken: "Email address already in use. Please enter a different email address. If you've previously created an account with Posti's business services, you can't use the same email again.",
        validationError: "Input field validation error. Please check your input fields and try again.",
      },
    },
    verifyPhone: {
      title: "Verify your phone number",
      codeSentTo: "A 6 digit verification code has been sent by SMS to",
      countdown: "The verification code is valid for",
      expired: "The verification code has expired. Request a new one or change a phone number.",
      newCodeSent: "A new verification code has been sent to your phone number.",
      enterCode: "Enter the verification code.",
      inputLabel: "Verification code",
      didNotReceive: "Did not receive the verification code?",
      sendNew: "Request new code",
      reEnterPhone: "Re-enter your phone number",
      validation: {
        codeError: "Verification code must have 6 digits",
        codeSuccess: "Verification code has 6 digits",
      },
      errorMessages: {
        invalidCode: "The code was incorrect or expired.",
        tooSoonAfterFailed: "Please wait a moment before trying again.",
        failedCode: "The code has been expired. Please request a new code from below.",
        pleaseTryAgainOr: "Please try again or",
        requestNew: "request a new code.",
        sendNewCodeError: "Failed to send a new verification link. This may happen if the verification link has been sent too many times. Please try again in one hour.",
        tooManyTimesTryAgain: "This may happen if the verification link has been sent too many times. Please try again in one hour.",
      },
      reEnterPhoneModal: {
        instruction: "Enter your new Finnish phone number below. You will receive an SMS with a verification code to verify your phone number.",
        sendCodeButton: "Request verification code",
      },
    },
    verifyEmail: {
      title: "Verify your email",
      weSentYou: "A verification message has been sent to:",
      email: "Email",
      instruction: "Finalize the registration by clicking the verification link that was sent to the email address you provided.",
      windowCanBeClosedInfo: "You can close this window or tab after verifying your email address.",
      didNotReceive: "Did not receive the email verification link?",
      checkSpamOr: "Check your spam folder or",
      reEnterEmail: "Re-enter your email address",
      sendNew: "Send new link",
      newLinkSent: "A new verification link has been sent to your email address.",
      errorMessages: {
        sendNewLinkError: "Failed to send a new verification link. This may happen if the verification link has been sent too many times. Please try again in one hour.",
        tooManyTimesTryAgain: "This may happen if the verification link has been sent too many times. Please try again in one hour.",
        reserved: "Email address is already in use.",
      },
      reEnterEmailModal: {
        instruction: "Enter your email address below. You will receive an email with a verification link to verify your email address.",
        sendLinkButton: "Send verification link",
      },
    },
    buttons: {
      proceedToCreate: "Proceed to account creation",
      createAccount: "Create account",
      updateAccount: "Update account",
      backToPosti: "Back to Posti services",
      gotoOmaPosti: "Go to OmaPosti",
      exitRegistration: "Exit",
      exit: "Exit",
    },
    setMissingPhone: {
      title: "Check your information",
      instruction: "Your phone number is missing. It is required for using Posti online services. Next you will be directed to verify your phone number. Click continue and follow the instructions.",
      errorMessages: {
        setPhoneNumberError: "Setting the phone number failed, please try again.",
      },
    },
  },
  accountVerified: {
    success: {
      registrationTitle: "Account verified!",
      emailChangedTitle: "Your email address has been changed",
      welcome: "Welcome! Your account is now verified. You can log in to OmaPosti to track parcels and receive invoices and letters.",
      continueToMyAccount: "My Account",
    },
    failure: {
      title: "Email verification failed",
      verificationFailedDueTo: "The email address verification failed. This could be due to a number of reasons:",
      reason1: "The verification code is invalid",
      reason2: "The verification code has expired",
      reason3: "The verification code has already been used",
      pleaseTryAgainAndReturnToMyAccount: "Please return to account details and try again.",
      pleaseTryAgainAndReturnToRegistration: "Please return to registration to try again.",
      returnToMyAccount: "Return to account details",
      returnToRegistration: "Return to registration",
    },
  },
  footer: {
    cookiePreferences: "Cookie settings",
  },
}
