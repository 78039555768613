import { Dropdown } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Localization from '../../locales'
import { infoStore, StoreContext } from '../../store'
import { ResponsiveBody } from '../typography/ResponsiveBody'

const options: any = {
  fi: [
    { value: 'fi', label: 'Suomi' },
    { value: 'sv', label: 'Ruotsi' },
    { value: 'en', label: 'Englanti' },
  ],
  sv: [
    { value: 'fi', label: 'Finska' },
    { value: 'sv', label: 'Svenska' },
    { value: 'en', label: 'Engelska' },
  ],
  en: [
    { value: 'fi', label: 'Finnish' },
    { value: 'sv', label: 'Swedish' },
    { value: 'en', label: 'English' },
  ],
}

const PreferredLanguageDropDownComponent: React.FC = () => {
  const { t } = useTranslation()

  const currentLanguage = Localization.CurrentLocale
  const { userStore } = useContext(StoreContext)

  // TODO this should be fetched from API https://postinext.atlassian.net/browse/OAS-1697
  const initialSelection = options[currentLanguage].find((p: any) => {
    return p.value === userStore.identities.consumerIdentity.communicationLanguage
  })

  const onChange = (p: any) => {
    userStore.setLanguage(p.value)
  }

  return (
    <div style={{ maxWidth: '430px' }}>
      <ResponsiveBody style={{ marginBottom: '0.5rem' }} as="label">
        <b>{t(`detailsView.comLang`)}</b>
      </ResponsiveBody>
      <Dropdown
        lightBackground
        inputId="languageDropdown"
        options={options[currentLanguage]}
        label={t(`detailsView.language`)}
        onChange={onChange}
        defaultValue={initialSelection}
        isDisabled={infoStore.isPartialConsumer}
      />
    </div>
  )
}

export const PreferredLanguageDropDown = observer(PreferredLanguageDropDownComponent)
