import { Button } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useContext, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory, withRouter } from 'react-router'

import { ButtonContainer, ContentContainer, PostiPageFooter } from '../../components'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import Localization, { Locale } from '../../locales'
import RegistrationService from '../../service/registration_service/RegistrationService'
import { RegistrationStep, StoreContext } from '../../store'
import { ConfusedErrorSvg, ListNotification, ListNotificationBody } from './registration.styles'

interface IProps {
  email?: string
}

type Props = RouteComponentProps & IProps

const RegistrationNotPossibleViewComponent: React.FC<Props> = observer(({ email }) => {
  const {
    registrationStore: { step },
  } = useContext(StoreContext)
  const requestProgressRef = useRef(false)
  const { t } = useTranslation()
  const history = useHistory()

  const lang: Locale = Localization.CurrentLocale as Locale

  const handleHardRedirect: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      const href = event.currentTarget?.value || '/'

      if (event.currentTarget?.value.includes('http')) {
        location.href = href
        return
      }

      history.push(href)
    },
    [history]
  )

  useEffect(() => {
    if (!requestProgressRef?.current) {
      RegistrationService.backgroundLogout()
      requestProgressRef.current = true
    }
  }, [])

  const renderHeader = () => {
    switch (step) {
      case RegistrationStep.PRE_CHECK_FAIL:
        return <ResponsiveHeadline as="h1">{t('registration.underMinAge.title')}</ResponsiveHeadline>
      case RegistrationStep.REGISTRATION_UNAVAILABLE:
        return <ResponsiveHeadline as="h1">{t('registration.unavailable.title')}</ResponsiveHeadline>
      default:
        return <ResponsiveHeadline as="h1">{t('registration.accountAlreadyExists.viewTitle')}</ResponsiveHeadline>
    }
  }

  const renderContent = () => {
    switch (step) {
      case RegistrationStep.PRE_CHECK_FAIL:
        return (
          <>
            <ConfusedErrorSvg />
            <ListNotification id="notification_container" contentType="alert" dismissible={false}>
              <>
                <ListNotificationBody>{t('registration.underMinAge.notificationText')}</ListNotificationBody>
                <div style={{ marginTop: '1rem' }}></div>
                <ListNotificationBody>
                  {t('registration.underMinAge.descriptionText')}
                  <ul>
                    <Trans i18nKey="registration.underMinAge.descriptionList" components={[<li />]} />
                  </ul>
                </ListNotificationBody>
              </>
            </ListNotification>
          </>
        )

      case RegistrationStep.REGISTRATION_UNAVAILABLE:
        return (
          <>
            <ConfusedErrorSvg />
            <ListNotification id="notification_container" contentType="alert" dismissible={false}>
              <>
                <ListNotificationBody>{t('registration.unavailable.notificationText')}</ListNotificationBody>
              </>
            </ListNotification>
          </>
        )

      default:
        return (
          <>
            <ConfusedErrorSvg />
            <ListNotification
              id="notification_container"
              contentType="success"
              dismissible={false}
              style={{ marginTop: '2rem', marginBottom: '1rem' }}
            >
              <>
                <ListNotificationBody>
                  {t('registration.accountAlreadyExists.youAlreadyHaveAccount')}&nbsp;
                  <span style={{ fontWeight: 700 }}>{email}</span>.
                </ListNotificationBody>
                <ListNotificationBody>
                  {t('registration.accountAlreadyExists.loginOrForgotPasswordInstruction')}
                </ListNotificationBody>
              </>
            </ListNotification>
          </>
        )
    }
  }

  const renderControls = () => {
    switch (step) {
      case RegistrationStep.PRE_CHECK_FAIL:
        return (
          <ButtonContainer style={{ justifyContent: 'space-between' }}>
            <Button
              id="button_cancel_registration_underage"
              contentMode="primary"
              hasBackground
              onClick={handleHardRedirect}
              value={`https://www.posti.fi/${Localization.CurrentLocale}`}
            >
              {t('registration.underMinAge.goToPostiFiButtonText')}
            </Button>
          </ButtonContainer>
        )

      case RegistrationStep.REGISTRATION_UNAVAILABLE:
        return (
          <ButtonContainer style={{ justifyContent: 'space-between' }}>
            <Button
              id="button_cancel_registration_unavailable"
              contentMode="primary"
              hasBackground
              onClick={handleHardRedirect}
              value={`https://www.posti.fi/${Localization.CurrentLocale}`}
            >
              {t('registration.unavailable.goToPostiFiButtonText')}
            </Button>
          </ButtonContainer>
        )

      default:
        return (
          <ButtonContainer style={{ justifyContent: 'flex-start' }} className={'swap-button-order-in-mobile'}>
            <Button
              id="button_goto_omaposti"
              contentMode="primary"
              hasBackground
              value={window['postienv'].omapostiUniversalLinks[lang]}
              onClick={handleHardRedirect}
            >
              {t('registration.buttons.gotoOmaPosti')}
            </Button>
            <Button
              id="button_forgot_my_password"
              contentMode="secondary"
              value={`/account-recovery-info?lang=${lang}`}
              onClick={handleHardRedirect}
            >
              {t('registration.accountAlreadyExists.buttonForgotMyPassword')}
            </Button>
          </ButtonContainer>
        )
    }
  }

  return (
    <ContentContainer>
      {renderHeader()}
      {renderContent()}
      {renderControls()}
      <PostiPageFooter showCookiePreferences />
    </ContentContainer>
  )
})

export const RegistrationNotPossibleView = withRouter(RegistrationNotPossibleViewComponent)
