import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import moment from 'moment-timezone'
import semver from 'semver'

import { serviceAdapter } from '../service/serviceAdapter'
import { userInfoResponseToUserInfoMapper } from '../utils/info'
import { IPhoneNumberVerificationResponse, IUserInfo } from './dataModels/interfaces'

export class InfoStore {
  userInfo: IUserInfo = {
    accountType: '',
    partialResponse: false,
    consumerInfo: {
      phoneNumber: '',
      phoneNumberVerified: false,
      termsAndConditionsAccepted: false,
    },
    consumerAccountExists: false,
    businessAccountExists: false,
    email: '',
    emailVerified: false,
    registrationStronglyAuthenticated: false,
    registrationVersion: '',
    hasAccess: false,
  }

  consumerRegistrationComplete: boolean = false

  isLoading: boolean = true

  constructor() {
    // @ts-ignore
    window.expireConsumerVerification = this.setPhoneVerificationExpiration

    makeObservable(this, {
      userInfo: observable,
      consumerRegistrationComplete: observable,
      isLoading: observable,
      isConsumer: computed,
      isCorporate: computed,
      error: observable,
      setConsumerRegistrationComplete: action,
      fetchUserInfo: action,
      setAccountCompleteness: action,
      updateExpirationData: action,
    })
  }

  get isPartial() {
    return this.userInfo.partialResponse
  }

  get isPartialConsumer() {
    return this.userInfo.partialResponse && this.userInfo.consumerAccountExists
  }

  get isConsumer(): boolean {
    return this.userInfo.consumerAccountExists
  }

  get hasAccess(): boolean {
    return this.userInfo.hasAccess
  }

  get isCorporate() {
    return Boolean(this.userInfo.businessAccountExists)
  }

  get isStrongAuth() {
    return Boolean(this.userInfo.registrationStronglyAuthenticated)
  }

  error: string = ''

  /**
   * sets the info object if data is requested elsewhere
   * @param info
   */
  public setInfo(info: IUserInfo) {
    this.userInfo = info
  }

  setConsumerRegistrationComplete = (p: boolean) => {
    this.consumerRegistrationComplete = p
  }

  fetchUserInfo = async (withoutLogin = false, background = false) => {
    if (!background) {
      this.isLoading = true
    }

    try {
      const response = !withoutLogin
        ? await serviceAdapter.sendGetRequest('/api/user/info')
        : await serviceAdapter.sendGetRequestWithoutLogin('/api/user/info')

      if (response.ok) {
        const data: IUserInfo = userInfoResponseToUserInfoMapper(await response.json())

        runInAction(() => {
          this.userInfo = data
          this.setAccountCompleteness()
        })

        return data
      } else if (response.status === 401) {
        throw response
      }
    } finally {
      this.isLoading = false
    }
  }

  updateExpirationData({ expiryDuration, isExpired, phoneNumber }: IPhoneNumberVerificationResponse) {
    if (!this.userInfo) {
      return null
    }

    const now = moment()
    const targetDateTime = now.add(expiryDuration.seconds, 'seconds')
    this.userInfo = {
      ...this.userInfo,
      phoneVerification: {
        phoneNumber,
        isExpired,
        expiryDate: targetDateTime.toDate() as Date,
      },
    }
  }

  setPhoneVerificationExpiration = (isExpired: boolean) => {
    if (this.userInfo?.phoneVerification) {
      this.userInfo.phoneVerification.isExpired = isExpired
    }
  }

  setAccountCompleteness = () => {
    this.setConsumerRegistrationComplete(this.isConsumerAccountComplete(this.userInfo))
  }

  /**
   * @param info
   * @returns true if consumer account is complete meaning that use has finished registration
   */
  isConsumerAccountComplete = (info: IUserInfo) => {
    return (
      info.consumerAccountExists &&
      info.consumerInfo.phoneNumberVerified &&
      info.emailVerified &&
      info.consumerInfo.termsAndConditionsAccepted &&
      info.hasAccess
    )
  }

  /**
   * @param info
   * @returns true if consumer or combo account that is missing access
   */
  isConsumerAccountMissingAccess = (info: IUserInfo) => {
    return info.consumerAccountExists && info.emailVerified && !info.hasAccess
  }

  /**
   * @param info
   * @returns true if consumer or combo account that is missing T&Cs
   */
  isConsumerAccountMissingTCs = (info: IUserInfo) => {
    return info.consumerAccountExists && !info.consumerInfo.termsAndConditionsAccepted
  }

  /**
   * @param info
   * @returns true if there is no data available for consumer account meaning that it is a completely new consumer user
   */
  isEmptyConsumerAccount = (info: IUserInfo): boolean => {
    return (
      !info.consumerAccountExists &&
      !info.emailVerified &&
      !info.email &&
      !info.consumerInfo.phoneNumber &&
      !info.consumerInfo.phoneNumberVerified &&
      !info.consumerInfo.termsAndConditionsAccepted
    )
  }

  /**
   * This is used to determine if the age of the user is under 15 years.
   * This will return false if the minimum age flag is not preset, this means, that the user is already registered.
   * @param info
   */
  isUnderAge = (info: IUserInfo): boolean => 'registrationMinimumAge' in info && !info.registrationMinimumAge

  /**
   * Determines the state when an already registered user looses a phone number due to someone else verifying it;
   * @param info
   */
  isMissingPhoneNumberAccount = (info: IUserInfo): boolean =>
    !!(
      info.consumerAccountExists &&
      info.registrationStronglyAuthenticated &&
      info.consumerInfo?.termsAndConditionsAccepted &&
      info.registrationVersion &&
      info?.consumerInfo?.phoneNumber === '' &&
      semver.gte(info.registrationVersion, '1.0.0') &&
      semver.lt(info.registrationVersion, '2.0.0') &&
      !info.phoneVerification?.phoneNumber
    )
}

export const infoStore = new InfoStore()
