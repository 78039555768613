import { action, makeObservable, observable } from 'mobx'
import moment from 'moment-timezone'

import RegistrationService from '../../service/registration_service/RegistrationService'
import { RegisterConsumerUserData } from '../../service/registration_service/ServiceModels'
import { serviceAdapter } from '../../service/serviceAdapter'
import { userInfoResponseToUserInfoMapper } from '../../utils/info'
import { encodeToBase64 } from '../../utils/password'
import { VERIFY_PHONE_ERROR } from '../../views/registration/verifyPhone/AlertNotification'
import {
  IChangeConsumerPhoneRequest,
  IPhoneNumberVerificationResponse,
  ISendVerifyPhoneCodeRequest,
  IUserInfo,
} from '../dataModels/interfaces'
import { infoStore } from '../infoStore'

const semverValid = require('semver/functions/valid')
const semverLt = require('semver/functions/lt')

export enum RegistrationStep {
  WELCOME = 'welcome',
  GOTO_START_REGISTRATION = 'gotoStartRegistration',
  REGISTRATION_FORM = 'registrationForm',
  REGISTRATION_FORM_FOR_OLD_USERS = 'registrationFormForOldUsers',
  VERIFY_PHONE = 'verifyPhone',
  VERIFY_EMAIL = 'verifyEmail',
  GOTO_ROOT = 'gotoRoot',
  ALREADY_REGISTERED = 'alreadyRegistered',
  PRE_CHECK_FAIL = 'preCheckFail',
  REGISTRATION_UNAVAILABLE = 'registrationUnavailable',
  // NOTE: Completion of registation via email link is handled by AccountVerifiedView in route /verify-email/:code
  PHONE_NUMBER_MISSING = 'phoneNumberMissingAfterRegistration',
}

export class RegistrationStore {
  info: IUserInfo | undefined

  email: string | undefined

  step: RegistrationStep | undefined

  infoReFetched: boolean = false

  loadingInfo: boolean = false

  // This is set to true if loadInfo() fails. It can be used by components to handle errors that occur when re-fetching info.
  infoRequestFailed: boolean = false

  processingRequest: boolean = false

  changeRegistrationEmailRequestFailed: boolean = false

  constructor() {
    // @ts-ignore
    window.expireRegistrationVerification = this.setPhoneVerificationExpiration

    makeObservable(this, {
      info: observable,
      email: observable,
      step: observable,
      infoReFetched: observable,
      loadingInfo: observable,
      infoRequestFailed: observable,
      processingRequest: observable,
      changeRegistrationEmailRequestFailed: observable,
      setInfo: action,
      setPhoneVerification: action,
      setStep: action,
      setInfoReFetched: action,
      setEmail: action,
      setLoadingInfo: action,
      setInfoRequestFailed: action,
      setChangeRegistrationEmailRequestFailed: action,
      setPhoneVerificationExpiration: action,
      loadInfo: action,
      createAccount: action,
      sendVerifyPhoneCode: action,
      changePhoneNumber: action,
      sendVerifyEmailLink: action,
      updateRegistrationEmail: action,
      setProcessingRequest: action,
    })
  }

  public setInfo(info: IUserInfo) {
    this.info = info
    registrationStore.setInfoReFetched(true)
    infoStore.setInfo(info)
  }

  public setStep(step: RegistrationStep) {
    this.step = step
  }

  public setInfoReFetched(param: boolean) {
    this.infoReFetched = param
  }

  public setEmail(newEmail: string) {
    registrationStore.info!.email = newEmail
  }

  public setPhone(newPhone: string) {
    if (newPhone) {
      registrationStore.info!.consumerInfo.phoneNumber = newPhone
    }
  }

  public setPhoneVerificationExpiration = (isExpired: boolean) => {
    if (this.info?.phoneVerification) {
      this.info.phoneVerification.isExpired = isExpired
    }
  }

  public setPhoneVerification({ expiryDuration, isExpired, phoneNumber }: IPhoneNumberVerificationResponse) {
    if (!this.info) {
      return null
    }
    const now = moment()
    const targetDateTime = now.add(expiryDuration.seconds, 'seconds')
    this.info = {
      ...this.info,
      phoneVerification: {
        phoneNumber,
        isExpired,
        expiryDate: targetDateTime.toDate() as Date,
      },
    }
    this.setInfoReFetched(true)
  }

  public setLoadingInfo(loadingInfo: boolean) {
    this.loadingInfo = loadingInfo
  }

  public setInfoRequestFailed(p: boolean) {
    this.infoRequestFailed = p
  }

  public setChangeRegistrationEmailRequestFailed(p: boolean) {
    this.changeRegistrationEmailRequestFailed = p
  }

  public loadInfo = async () => {
    try {
      this.setLoadingInfo(true)
      this.setInfoRequestFailed(false)

      const rawResponse: IUserInfo = await RegistrationService.getInfo()
      const response: IUserInfo = userInfoResponseToUserInfoMapper(rawResponse)

      this.setInfo(response)
      const step: RegistrationStep = this.getRegistrationStep(response)

      if (step === RegistrationStep.GOTO_START_REGISTRATION) {
        location.href = '/start-registration'
      } else if (step === RegistrationStep.GOTO_ROOT) {
        location.href = '/'
      } else {
        this.setStep(step)
      }
      this.setLoadingInfo(false)
    } catch (e) {
      this.setLoadingInfo(false)
      this.setInfoRequestFailed(true)
      // false means 401 not authenticated, redirect to first registration step
      if (e === false) {
        this.setStep(RegistrationStep.WELCOME)
      } else {
        throw e
      }
    }
  }

  createAccount = async (data: RegisterConsumerUserData, usePatch: boolean) => {
    this.setProcessingRequest(true)
    try {
      const response = await RegistrationService.registerConsumerUser(
        {
          ...data,
          password: encodeToBase64(data.password),
          passwordEncoded: true,
        },
        usePatch
      )

      if (response.ok) {
        await this.loadInfo()
        return response
      } else {
        throw await response.json()
      }
    } finally {
      this.setProcessingRequest(false)
    }
  }

  sendVerifyPhoneCode = async (data: ISendVerifyPhoneCodeRequest) => {
    this.setProcessingRequest(true)
    try {
      const response = await serviceAdapter.sendPostRequest('/api/registerconsumer/verifyphone', {
        phone: data.phone,
        code: data.code,
      })
      if (response.ok) {
        await this.loadInfo()
        return response
      } else if (response.status >= 400 && response.status < 500) {
        throw await response.json()
      } else {
        throw VERIFY_PHONE_ERROR.INTERNAL_ERROR
      }
    } finally {
      this.setProcessingRequest(false)
    }
  }

  changePhoneNumber = async (data: IChangeConsumerPhoneRequest, reloadInfo: boolean = false) => {
    this.setProcessingRequest(true)
    try {
      const response = await serviceAdapter.sendPostRequest('/api/registerconsumer/initverifyphone', {
        phone: data.phone,
        language: data.language,
      })
      if (response.ok) {
        // reloadInfo=true is used when changing the phone number during registration in order to reinit the view with new phone number
        if (reloadInfo) {
          this.loadInfo().then(() => {
            this.setInfoReFetched(true)
          })
        } else {
          try {
            const data: IPhoneNumberVerificationResponse = await response.json()
            registrationStore.setPhoneVerification(data)
          } catch (error) {
            console.error('Error parsing JSON:', error)
          }
          return response
        }
      } else {
        throw response
      }
    } finally {
      this.setProcessingRequest(false)
    }
  }

  sendVerifyEmailLink = async (newEmail: string) => {
    this.setProcessingRequest(true)
    try {
      const response = await serviceAdapter.sendPostRequest('/api/registerconsumer/initverifyemail', {
        email: newEmail,
        isConsumerRegistration: true,
      })
      if (response.ok) {
        return response
      } else {
        throw response
      }
    } finally {
      this.setProcessingRequest(false)
    }
  }

  updateRegistrationEmail = async (newEmail: string) => {
    this.setProcessingRequest(true)
    this.setChangeRegistrationEmailRequestFailed(false)
    try {
      const response = await serviceAdapter.sendPostRequest('/api/registerconsumer/initverifyemail', {
        email: newEmail,
        isConsumerRegistration: true,
      })
      if (response.ok) {
        this.setEmail(newEmail)
        this.setInfoReFetched(true)
      } else {
        this.setChangeRegistrationEmailRequestFailed(true)
        throw await response?.json()
      }
    } finally {
      this.setProcessingRequest(false)
    }
  }

  setProcessingRequest = (p: boolean) => {
    this.processingRequest = p
  }

  isOldIncompleteAccount = (info: IUserInfo): boolean => {
    if (infoStore.isEmptyConsumerAccount(info)) {
      return false
    }

    const registrationVersionIsOld =
      !semverValid(info.registrationVersion) || semverLt(info.registrationVersion, '1.0.0')

    return registrationVersionIsOld && !infoStore.isConsumerAccountComplete(info)
  }

  getRegistrationStep = (info: IUserInfo): RegistrationStep => {
    // Registration is not for corporate accounts in any state

    if (infoStore.isCorporate && !infoStore.isConsumer) {
      return RegistrationStep.GOTO_ROOT
    }

    // underage people do not see the registration form

    if (infoStore.isPartial && infoStore.isCorporate && infoStore.hasAccess) {
      return RegistrationStep.GOTO_ROOT
    }

    if (infoStore.isUnderAge(info)) {
      return RegistrationStep.PRE_CHECK_FAIL
    }

    if (infoStore.isPartial) {
      return RegistrationStep.REGISTRATION_UNAVAILABLE
    }

    // not strongly authenticated for registration
    if (!info.registrationStronglyAuthenticated) {
      if (infoStore.isConsumerAccountComplete(info)) {
        return RegistrationStep.GOTO_ROOT
      } else {
        return info.consumerAccountExists ? RegistrationStep.GOTO_START_REGISTRATION : RegistrationStep.WELCOME
      }
    }

    if (infoStore.isConsumerAccountMissingAccess(info) || infoStore.isConsumerAccountMissingTCs(info)) {
      return RegistrationStep.REGISTRATION_FORM_FOR_OLD_USERS
    }

    /* is strongly authenticated for registration */
    if (infoStore.isMissingPhoneNumberAccount(info)) {
      return RegistrationStep.PHONE_NUMBER_MISSING
    }

    // vanilla account / new registration
    if (infoStore.isEmptyConsumerAccount(info)) {
      return RegistrationStep.REGISTRATION_FORM
    }

    // This is an old user and will be shown the registration form for old users
    // After user finishes the form and creates an account, registrationVersion will be updated to "1.0.0"
    // and the rest of the registration flow will continue normally
    if (this.isOldIncompleteAccount(info)) {
      return RegistrationStep.REGISTRATION_FORM_FOR_OLD_USERS
    }

    // phone number not verified
    if (!info.consumerInfo.phoneNumberVerified) {
      return RegistrationStep.VERIFY_PHONE
    }

    // email not verified
    if (!info.emailVerified) {
      return RegistrationStep.VERIFY_EMAIL
    }

    // already registered
    return RegistrationStep.ALREADY_REGISTERED
  }
}

export const registrationStore = new RegistrationStore()
